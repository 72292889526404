// filename: src/router/routes.js
import HomePage from '@/views/HomePage.vue';
import ClientRegistrationClientForm from "@/components/Auth/ClientRegistrationClientForm.vue";
import MemorialProfileActivationForm from "@/components/Auth/MemorialProfileActivationForm.vue";
import AboutUs from "@/views/AboutUs.vue";
import LoginForm from "@/components/Auth/LoginForm.vue";
import DashboardPage from "@/views/DashboardPage.vue";
import MemorialProfile from "@/views/MemorialProfile.vue";
import MemProfCreationParent from "@/components/MemorialProfileCreationForm/MemProfCreationParent.vue";
import ContactPage from "@/views/ContactPage.vue";
import ForgotPasswordForm from "@/components/Auth/ForgotPasswordForm.vue";
import ChangePasswordForm from "@/components/Auth/ChangePasswordForm.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";
import TermsOfService from "@/views/TermsOfService.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import AccessCodeForm from "@/components/AccessCodeForm.vue";

// Define route names as constants
export const ROUTE_NAMES = {
  HOME: 'home',
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  CLIENT_REGISTRATION_CLIENT: 'client-registration-client',
  MEMORIAL_PROFILE_ACTIVATION: 'profile-activation',
  ACCESS_CODE_VERIFICATION: 'access-code-verification',
  ABOUT_US: 'about-us',
  SAMPLE_PROFILE: 'memorial-profile-sample',
  CREATE_MEMORIAL_PROFILE: 'create-mem-profile',
  MEMORIAL_PROFILE_VIEW: 'memorial-profile-view',
  MEMORIAL_PROFILE_EDIT: 'memorial-profile-edit',
  CONTACT: 'contact',
  FORGOT_PASSWORD: 'forgot-password',
  CHANGE_PASSWORD: 'change-password',
  TERMS_OF_SERVICE: 'terms-of-service',
  PRIVACY_POLICY: 'privacy-policy',
};

const routes = [
  {
    path: '/',
    name: ROUTE_NAMES.HOME,
    component: HomePage
  },
  {
    path: '/rejestracja-klienta',
    name: ROUTE_NAMES.CLIENT_REGISTRATION_CLIENT,
    component: ClientRegistrationClientForm
  },
  {
    path: '/b2b',
    redirect: () => {
      return {
        name: ROUTE_NAMES.MEMORIAL_PROFILE_ACTIVATION,
      }
    }
  },
  {
    path: '/aktywacja-profilu',
    name: ROUTE_NAMES.MEMORIAL_PROFILE_ACTIVATION,
    component: MemorialProfileActivationForm,
  },
  {
    path: '/o-nas',
    name: ROUTE_NAMES.ABOUT_US,
    component: AboutUs
  },
  {
    path: '/przykladowy-profil',
    name: ROUTE_NAMES.SAMPLE_PROFILE,
    redirect: () => {
      return {
        name: ROUTE_NAMES.MEMORIAL_PROFILE_VIEW,
        params: {slotNumber: '55-01-27'},
      }
    },
  },
  {
    path: '/memorial-profile-sample',
    redirect: '/przykladowy-profil',
  },
  {
    path: '/login',
    name: ROUTE_NAMES.LOGIN,
    component: LoginForm
  },
  // Route for URLs ending with 78-98-43
  {
    path: '/profil/:pathMatch(.*78-98-43)',
    redirect: () => {
      return {
        name: ROUTE_NAMES.MEMORIAL_PROFILE_VIEW,
        params: { slotNumber: '55-01-27' },
      }
    }
  },
  {
    path: '/panel-uzytkownika',
    name: ROUTE_NAMES.DASHBOARD,
    component: DashboardPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/profil/:slotNumber',
    name: ROUTE_NAMES.MEMORIAL_PROFILE_VIEW,
    component: MemorialProfile,
    props: true,
    meta: { requiresAuth: false },
  },
  // sprawdzić, gdzie przekierowują pierwsze 120 qr blaszek i ewentualnie usunąć
  {
    path: '/memorial_profile/:slotNumber',
    redirect: to => {
      return {
        name: ROUTE_NAMES.MEMORIAL_PROFILE_VIEW,
        params: to.params,
      }
    }
  },
  {
    path: '/profil/:slotNumber/autoryzacja',
    name: ROUTE_NAMES.ACCESS_CODE_VERIFICATION,
    component: AccessCodeForm,
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/profil-pamieci/:slotNumber/edytuj',
    name: ROUTE_NAMES.MEMORIAL_PROFILE_EDIT,
    component: MemProfCreationParent,
    props: true,
    meta: {requiresAuth: true}
  },
  {
    path: '/cennik',
    redirect: to => {
      return {
        name: ROUTE_NAMES.CONTACT,
        params: to.params,
      }
    }
  },
  {
    path: '/kontakt',
    name: ROUTE_NAMES.CONTACT,
    component: ContactPage
  },
  {
    path: '/zapomnialem-haslo',
    name: ROUTE_NAMES.FORGOT_PASSWORD,
    component: ForgotPasswordForm
  },
  {
    path: '/zmiana-hasla',
    name: ROUTE_NAMES.CHANGE_PASSWORD,
    component: ChangePasswordForm,
    meta: {requiresAuth: true}
  },
  {
    path: '/regulamin',
    name: ROUTE_NAMES.TERMS_OF_SERVICE,
    component: TermsOfService
  },
  {
    path: '/polityka-prywatnosci',
    name: ROUTE_NAMES.PRIVACY_POLICY,
    component: PrivacyPolicy
  },
  // Wildcard route - make sure this is the last route in the array
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundPage
  }
];

export default routes;
